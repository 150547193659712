.editUserBtn {
  font-size: 20pt;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 20px;
  cursor: pointer;
}

.delUserBtn {
  font-size: 20pt;
  vertical-align: middle;
  margin-top: -3px;
  cursor: pointer;
}

.editUserBtn:hover,
.delUserBtn:hover {
  color: red;
}

.passInvalid {
  box-shadow: 0px 0px 5px red;
}

.tblAdmBtnFld {
  min-width: 100px;
}

.tblGapiEmailFld {
  min-width: 250px;
}

.tblUsernameFld {
  min-width: 150px;
}

.tblTypeFld {
  min-width: 100px;
}

.tblLevelCnt {
  min-width: 100px;;
}
