@import '../../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../../node_modules/bulma/sass/utilities/derived-variables.sass';

section {
  outline: 0;
}

.editorContainer {
  h1 {
    font-size: 20pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.tutorialLayout {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 400px;
  height: 200px;
  border: 1px solid #006888;
  background: #003748;
  box-shadow: 0 0 5px #000;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-size: 14pt;

  .container {
    position: relative;
  }

  .buttonArea {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  button {
    margin-left: 10px;
  }
}

.durationSelector {
  input {
    user-select: none;
    cursor: pointer;
  }

  input.error {
    color: red;
    font-weight: bold;
  }

  .secondSelector {
    margin-top: 10px;
  }

  .timeElement {
    display: inline-block;
    text-align: center;
    width: 40px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;

    &.selected {
      background-color: #30394a;
      color: white;
    }

    &.notSelected {
      background-color: white;
      color: black;
    }
  }

  .popover {
    background-color: #888;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    width: 200px;
    height: 200px;
    font-size: 13px;
    margin-top: 10px;

    &.hidden {
      display: none;
    }

    .popoverArrow {
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #888 transparent;
      top: -10px;
      width: 10px;
      height: 10px;
    }
  }
}

.timeLimitEditor {
  .tlstar {
    max-width: 30px;
    margin-right: 3px;
  }

  .tlContainer {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    input {
      max-width: 100px;
      text-align: center;
    }
  }

  .tlstarContainer {
    text-align: right;
    min-width: 100px;
    margin-right: 10px;
    display: inline-block;
  }
}

.levelGrid {
  border: 1px solid black;
  display: inline-block;
  line-height: 0;
  user-select: none;
}

.gridRow {
  display: flex;
}

.gridCol {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.checker {
    background-color: #ccc;
  }

  &.canDrop {
    background-color: green;
  }

  &.cannotDrop {
    background-color: red;
  }
}

.editorCenterColumn {
  text-align: center;
  min-width: 672px;
}

.editorNotification {
  border: 1px solid #ccc;
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px;
}

.draggableItem {
  display: inline-block;
}

.levelButtonContainer {
  display: flex;
  flex-wrap: wrap;
}

.levelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14pt;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  user-select: none;

  &.invalid {
    background-color: red;
    color: white;
  }

  &.selected.valid {
    border: 3px solid #30394a;
    color: white;
  }

  &.selected.invalid {
    border: 3px solid #30394a;
    background-color: red;
    color: white;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    background-color: #ccc;
  }
}

.trashcan {
  border: 1px solid black;
  height: 80px;
  margin-top: 50px;
  font-size: 25pt;
  display: flex;
  justify-content: center;
  align-items: center;

  &.canDrop {
    background-color: green;
  }

  &.cannotDrop {
    background-color: red;
  }
}

.modalErrMessage {
  margin-top: 2em;
  color: red;
  font-weight: bold;
}

.sidebarButtonRow {
  margin-bottom: 5px;
}

.sidebarButton {
  cursor: pointer;
  border-width: 1px;
  text-align: center;
  padding: 0.35em 0.75em;
  background-color: white;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5em;
  margin-right: 5px;
  min-width: 150px;
}

.deleteLevelButton {
  background-color: #ccc;
}

.levelNotModified {
  background-color: #ccc;
}

.levelModified {
  background-color: $info;
  color: white;
}

.editorRightBlock {
  user-select: none;
}
