$navbar-background-color: #30394a;
$navbar-item-color: #ccc;

@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/layout/section.sass';
@import '../../node_modules/bulma/sass/elements/button.sass';
@import '../../node_modules/bulma/sass/elements/container.sass';
@import '../../node_modules/bulma/sass/elements/icon.sass';
@import '../../node_modules/bulma/sass/elements/other.sass';
@import '../../node_modules/bulma/sass/elements/table.sass';
@import '../../node_modules/bulma/sass/grid/columns.sass';
@import '../../node_modules/bulma/sass/components/navbar.sass';
@import '../../node_modules/bulma/sass/components/modal.sass';

html, body, #root, .container {
  height: 100%;
}

.loginViewContainer {
  height: 100%;
}

.loginViewBox {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 8px #888888;
  border-radius: 5px;
  padding: 20px;
}

.importLevelsDropzone {
  height: 100px;
  border: 1px dashed gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}